import { config } from "./config";
import { hideLoadingDialog, showLoadingDialog } from "./dialogs";
const { CURRENT_URL, CURRENT_PAGE, ROLES_ARRAY, isExternalPathHas, getRoleString, updatePropertyValue, CONSOLE_LOG } = require("./module");
const isExternalPaths = isExternalPathHas();

// Define your menu data
const menuData = [
    {
        type: "item",
        href: 'index.html',
        text: 'Dashboard',
        icon: 'menu-icon tf-icons bx bx-home-circle',
        submenu: []
    },
    {
        type: "header",
        class: "menu-header small text-uppercase",
        html: `<span class="menu-header-text">Pages</span>`
    },
    {
        type: "item",
        text: 'Event',
        href: 'event/view-list.html',
        icon: 'menu-icon tf-icons bx bx-calendar-event',
        submenu: [
          { href: 'event/add-new.html', text: 'Add Item' },
          { href: 'event/view-list.html', text: 'View All' }
        ]
    },
    {
      type: "item",
      text: 'News',
      href: 'news/view-list.html',
      icon: 'menu-icon tf-icons bx bx-dock-top',
      submenu: [
        { href: 'news/add-new.html', text: 'Add Item' },
        { href: 'news/view-list.html', text: 'View All' }
      ]
    },
    {
      type: "item",
      text: 'Project',
      href: 'project/view-list.html',
      icon: 'menu-icon tf-icons bx bx-money-withdraw',
      submenu: [
        { href: 'project/add-new.html', text: 'Add Item' },
        { href: 'project/view-list.html', text: 'View All' }
      ]
    },
    {
      type: "item",
      text: 'Hope Connect',
      href: 'connect/view-list.html',
      icon: 'menu-icon tf-icons bx bx-book-reader',
      submenu: [
        { href: 'connect/add-new.html', text: 'Add Item' },
        { href: 'connect/view-list.html', text: 'View All' }
      ]
    },
    {
        type: "item",
        text: 'Gallery',
        href: 'gallery/view-list.html',
        icon: 'menu-icon tf-icons bx bx-image-alt',
        submenu: [
          { href: 'gallery/add-new.html', text: 'Add Item' },
          { href: 'gallery/view-list.html', text: 'View All' }
        ]
    },
    {
        type: "header",
        class: "menu-header small text-uppercase",
        html: `<span class="menu-header-text">Forms</span>`
    },
    {
        type: "item",
        text: 'Contacts',
        href: 'forms/contact-us.html',
        icon: 'menu-icon tf-icons bx bx-message-rounded-dots',
        submenu: []
    },
    {
        type: "item",
        text: 'Volunteers',
        href: 'forms/volunteers.html',
        icon: 'menu-icon tf-icons bx bxs-face',
        submenu: []
    },
    {
        type: "header",
        class: "menu-header small text-uppercase",
        html: `<span class="menu-header-text">Setting</span>`
    },
    {
        type: "item",
        text: 'Account',
        href: 'accounts.html',
        icon: 'menu-icon tf-icons bx bx-dock-top',
        submenu: []
    }
];

// Data array for navbar title
const menuTitleData = [
  { href: '/', title: "Home" },
  { href: '/index.html', title: "Home" },
  { href: '/event/add-new.html', title: "Create Event" },
  { href: '/event/view-list.html', title: "View Events" },
  { href: '/news/add-new.html', title: "Create News" },
  { href: '/news/view-list.html', title: "View News" },
  { href: '/project/add-new.html', title: "Create Project" },
  { href: '/project/view-list.html', title: "View Projects" },
  { href: '/connect/add-new.html', title: "Create Hope Connect" },
  { href: '/connect/view-list.html', title: "View Hope Connect" },
  { href: '/gallery/add-new.html', title: "Add New Gallery" },
  { href: '/gallery/view-list.html', title: "View Gallery" },
  { href: '/forms/contact-us.html', title: "View Contacts" },
  { href: '/forms/volunteers.html', title: "View Volunteers" },
  { href: '/accounts.html', title: "Account" }
];

// Data object for navbar
const user = {
  profileURL: `${config.app.website}/storage/profile_icon/user.jpg`,
  name: 'User name',
  role: 1,
};

const initializedAsideBar = createAsideNav(menuData);
if(initializedAsideBar){
  if (CONSOLE_LOG) console.log("Aside bar created successfully:", initializedAsideBar);
}

const initializedNavbar = createNavbar(user);
if(initializedNavbar){
  if (CONSOLE_LOG) console.log("Navbar created successfully:", result);
  // Call the function to create the footer
  $(document).ready(function() {
    if (CONSOLE_LOG) console.log('jQuery is available here!');
    hideLoadingDialog();
    updatePropertyValue('profile_picture', "src", user.profileURL);
  });
}
const initializedFooter = createFooter();

document.addEventListener('DOMContentLoaded', function() {

});
// Function to create the Aside HTML
function createAsideNav(menuData) {
  const asideMenu = document.getElementById('layout-menu');
  if (asideMenu) {
    const appBrand = document.createElement('div');
    appBrand.classList.add("app-brand", "demo");
    appBrand.innerHTML = `
    <a href="index.html" class="app-brand-link app-brand-name">
        <!-- <img class="app-brand-logo demo" src="../assets/img/logo/logo-hz.png" alt="" srcset=""> -->
        <span class="app-brand-sub-name">AS</span> Panel
    </a>
    <a href="javascript:void(0);" class="layout-menu-toggle menu-link text-large ms-auto d-block d-xl-none">
        <i class="bx bx-chevron-left bx-sm align-middle"></i>
    </a>`;
  
    const ul = document.createElement('ul');
    ul.classList.add('menu-inner', 'py-1');
  
    menuData.forEach(item => {
        let li;
        if (item.type === "header") {
            li = document.createElement('li');
            li.className = item.class;
            li.innerHTML = item.html;
        } else if (item.type === "item") {
            li = document.createElement('li');
            li.classList.add('menu-item');
  
            const a = document.createElement('a');
            a.classList.add('menu-link');
            if (isExternalPaths) {
                a.href = `../${item.href}`;
            } else {
                a.href = item.href;
            }
            a.innerHTML = `<i class="${item.icon}"></i><div data-i18n="${item.text}">${item.text}</div>`;
  
            if (window.location.pathname.endsWith(item.href)) {
                li.classList.add('active');
            }
  
            if (item.submenu.length > 0) {
                a.classList.add('menu-toggle');
                const submenuUl = document.createElement('ul');
                submenuUl.classList.add('menu-sub');
  
                item.submenu.forEach(subitem => {
                    const submenuLi = document.createElement('li');
                    submenuLi.classList.add('menu-item');
  
                    const submenuA = document.createElement('a');
                    submenuA.classList.add('menu-link');
                    if (isExternalPaths) {
                      submenuA.href = `../${subitem.href}`;
                    } else {
                      submenuA.href = subitem.href;
                    }
                    submenuA.innerHTML = `<div>${subitem.text}</div>`;
  
                    if (window.location.pathname.endsWith(subitem.href)) {
                        submenuLi.classList.add('active');
                        li.classList.add('active');
                    }
  
                    submenuLi.appendChild(submenuA);
                    submenuUl.appendChild(submenuLi);
                });
  
                li.appendChild(a);
                li.appendChild(submenuUl);
            } else {
                li.appendChild(a);
            }
        }
        ul.appendChild(li);
    });
  
    const meneInnerShadow = document.createElement("div");
    meneInnerShadow.classList.add("menu-inner-shadow");
  
    asideMenu.appendChild(appBrand);
    asideMenu.appendChild(meneInnerShadow);
    asideMenu.appendChild(ul);
    return true;
  }
}

// Function to create the Navbar HTML
function createNavbar(data) {
  // Get the pathname part of the URL
  const pathname = new URL(window.location.href).pathname;

  const navbar = document.getElementById('layout-navbar');
  const currentPageItem = menuTitleData.find(item => item.href === pathname);
  const currentPageTitle = currentPageItem ? currentPageItem.title : '';

  // Menu toggle button
  const menuToggleButton = `
    <div class="navbar-nav align-items-xl-center me-2 me-xl-0 d-xl-none">
      <button type="button" class="layout-menu-toggle transparent-btn"><i class="bx bx-menu bx-sm"></i></button>
    </div>`;

  // Search bar
  const searchBar = `
    <div class="navbar-nav-right d-flex align-items-center" id="navbar-collapse">
      <div class="navbar-nav align-items-center">
        <div class="nav-item d-flex align-items-center">
          <h4 class="m-0 nav-title">${currentPageTitle}</h4>
        </div>
      </div>
  `;

  // User dropdown
  let userDropdown = `
    <ul class="navbar-nav flex-row align-items-center ms-auto">
      <li class="nav-item navbar-dropdown dropdown-user dropdown">
        <a class="nav-link dropdown-toggle hide-arrow" href="javascript:void(0);" data-bs-toggle="dropdown">
          <div class="avatar avatar-online">
            <img src="${data.profileURL}" alt class="w-px-40 h-auto rounded-circle profile_picture" />
          </div>
        </a>
        <ul class="dropdown-menu dropdown-menu-end">
          <li>
            <a class="dropdown-item" href="accounts.html">
              <div class="d-flex">
                <div class="flex-shrink-0 me-3">
                  <div class="avatar avatar-online">
                    <img src="${data.profileURL}" alt class="w-px-40 h-auto rounded-circle profile_picture" />
                  </div>
                </div>
                <div class="flex-grow-1">
                  <span class="fw-semibold d-block profile_name">${data.name}</span>
                  <small class="text-muted profile_role">${getRoleString(data.role)}</small>
                </div>
              </div>
            </a>
          </li>
          <li>
            <div class="dropdown-divider"></div>
          </li>
  `;

  const links = [
      { href: '../accounts.html#tab=navs-profile', icon: 'bx bx-user', text: 'My Profile' },
      { href: '../accounts.html#tab=navs-roles', icon: 'bx bx-cog', text: 'Manage Roles' },
  ];

  // User links
  links.forEach(link => {
      userDropdown += `
        <li>
          <a class="dropdown-item" href="${link.href}">
            <i class="${link.icon} me-2"></i>
            <span class="align-middle">${link.text}</span>
          </a>
        </li>
      `;
  });

  const logoutLink = isExternalPaths ? "../auth/login.html?action=logout" : "./auth/login.html?action=logout";
  // Logout link with divider
  userDropdown += `
          <li>
            <div class="dropdown-divider"></div>
          </li>
          <li>
            <a class="dropdown-item text-danger" href="${logoutLink}">
              <i class="bx bx-power-off me-2"></i>
              <span class="align-middle">Log Out</span>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  `;

  if(navbar){
    navbar.innerHTML = menuToggleButton + searchBar + userDropdown;
  }
}

// Function to create the Footer HTML
function createFooter(){
  // Create the footer element
  const footer = document.getElementById('footer');
  if (CONSOLE_LOG) console.log("Footer:", footer);
  // Create the container div
  const container = document.createElement('div');
  container.className = 'container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column';

  // Append the inner div to the container
  container.innerHTML = `
  <div class="mb-0">
    <p>Developed by <a href="https://affixstudio.co" class="footer-link fw-bolder" target="_blank">Affix Studio</a></p>
  </div>
  <div>
    <a href="https://www.youtube.com/playlist?list=PLoz_Lgfx8XjTP4H7P-xyu6fle0ocxe3m8" target="_blank" class="footer-link me-4">Tutorials</a>
    <a href="mailto:support@affixstudio.co" target="_blank" class="footer-link me-4">Support</a>
  </div>`;
  // Append the container to the footer
  if(footer){
    if (CONSOLE_LOG) console.log("Footer:", footer);
    footer.appendChild(container);
  }
  return true;
}

// Function to Modify the Navbar
export function modifyNavbar(data){
  const profile_picture = document.querySelectorAll(".profile_picture");
  profile_picture.forEach(picture => {
    if(data.profileURL !== "0"){
      picture.src = data.profileURL;
    } else {
      picture.src = "../assets/img/avatars/user.jpg";
    }
  });

  const profile_name = document.querySelectorAll(".profile_name");
  profile_name.forEach(name => {
    name.textContent = data.name;
  });

  const profile_role = document.querySelectorAll(".profile_role");
  profile_role.forEach(role => {
    role.textContent = getRoleString(data.role);
  });
}


