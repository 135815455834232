/**
 * Main
 */

'use strict';

const { alertModel, alertPromptModel, hideLoadingDialog } = require("./dialogs");
const { articleType, ACCESS_OBJECT, fetchDataFromServer, getGlobalUserEmail, CURRENT_PAGE, showTheCurrentTimer, setProfileObject, setGlobalUserEmail, isExternalPathHas, decodeJWT, ENUM_PROFILE, CONSOLE_LOG } = require("./module");
const { modifyNavbar } = require("./nav");

let menu, animate;

(function () {
  // Initialize menu
  //-----------------

  let layoutMenuEl = document.querySelectorAll('#layout-menu');
  layoutMenuEl.forEach(function (element) {
    menu = new Menu(element, {
      orientation: 'vertical',
      closeChildren: false
    });
    // Change parameter to true if you want scroll animation
    window.Helpers.scrollToActive((animate = false));
    window.Helpers.mainMenu = menu;
  });

  // Initialize menu togglers and bind click on each
  let menuToggler = document.querySelectorAll('.layout-menu-toggle');
  menuToggler.forEach(item => {
    item.addEventListener('click', event => {
      event.preventDefault();
      window.Helpers.toggleCollapsed();
    });
  }); 

  // Display menu toggle (layout-menu-toggle) on hover with delay
  let delay = function (elem, callback) {
    let timeout = null;
    elem.onmouseenter = function () {
      // Set timeout to be a timer which will invoke callback after 300ms (not for small screen)
      if (!Helpers.isSmallScreen()) {
        timeout = setTimeout(callback, 300);
      } else {
        timeout = setTimeout(callback, 0);
      }
    };

    elem.onmouseleave = function () {
      // Clear any timers set to timeout
      document.querySelector('.layout-menu-toggle').classList.remove('d-block');
      clearTimeout(timeout);
    };
  };
  if (document.getElementById('layout-menu')) {
    delay(document.getElementById('layout-menu'), function () {
      // not for small screen
      if (!Helpers.isSmallScreen()) {
        document.querySelector('.layout-menu-toggle').classList.add('d-block');
      }
    });
  }

  // Display in main menu when menu scrolls
  let menuInnerContainer = document.getElementsByClassName('menu-inner'),
    menuInnerShadow = document.getElementsByClassName('menu-inner-shadow')[0];
  if (menuInnerContainer.length > 0 && menuInnerShadow) {
    menuInnerContainer[0].addEventListener('ps-scroll-y', function () {
      if (this.querySelector('.ps__thumb-y').offsetTop) {
        menuInnerShadow.style.display = 'block';
      } else {
        menuInnerShadow.style.display = 'none';
      }
    });
  }



  // Init helpers & misc
  // --------------------

  // Init BS Tooltip
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // Accordion active class
  const accordionActiveFunction = function (e) {
    if (e.type == 'show.bs.collapse' || e.type == 'show.bs.collapse') {
      e.target.closest('.accordion-item').classList.add('active');
    } else {
      e.target.closest('.accordion-item').classList.remove('active');
    }
  };

  const accordionTriggerList = [].slice.call(document.querySelectorAll('.accordion'));
  const accordionList = accordionTriggerList.map(function (accordionTriggerEl) {
    accordionTriggerEl.addEventListener('show.bs.collapse', accordionActiveFunction);
    accordionTriggerEl.addEventListener('hide.bs.collapse', accordionActiveFunction);
  });

  // Auto update layout based on screen size
  window.Helpers.setAutoUpdate(true);

  // Toggle Password Visibility
  window.Helpers.initPasswordToggle();

  // Speech To Text
  window.Helpers.initSpeechToText();

  // Manage menu expanded/collapsed with templateCustomizer & local storage
  //------------------------------------------------------------------

  // If current layout is horizontal OR current window screen is small (overlay menu) than return from here
  if (window.Helpers.isSmallScreen()) {
    return;
  }

  // If current layout is vertical and current window screen is > small

  // Auto update menu collapsed/expanded based on the themeConfig
  window.Helpers.setCollapsed(true, false);
})();

document.addEventListener('DOMContentLoaded', (event) => {

});

let globalUserEmail;
const profileStorage = localStorage.getItem(ENUM_PROFILE);
const profile = decodeJWT(profileStorage);
if(profile){
  if (CONSOLE_LOG) console.log("Decode ProfileJWT:", profile);
  modifyNavbar(profile);
  setProfileObject(profile);
  setGlobalUserEmail(profile.email);
  globalUserEmail = profile.email;
} else {
  if(CURRENT_PAGE !== "login.html" && CURRENT_PAGE !== "forget-password.html"){
    const path = isExternalPathHas ? "../auth/login.html" : "./auth/login.html";
    if (CONSOLE_LOG) console.log("Rendering PATH:", path);
    window.location = path;
  }
}



function getFileExtension(url) {
  // Split the URL by periods
  let parts = url.split('.');
  // Get the last part which should be the file extension
  let extension = parts[parts.length - 1];
  // Return the extension
  return extension;
}

function getMimeTypeFromExtension(url) {
  const extension = getFileExtension(url);
  const extensionToMimeType = {
      'jpg': 'image/jpeg',
      'jpeg': 'image/jpeg',
      'png': 'image/png',
      'gif': 'image/gif',
      'pdf': 'application/pdf'
  };
  // Convert extension to lowercase to handle case insensitivity
  let ext = extension.toLowerCase();
  // Check if the extension exists in our mapping
  if (extensionToMimeType.hasOwnProperty(ext)) {
      return extensionToMimeType[ext];
  } else {
      // Return a default MIME type or handle accordingly
      return 'application/octet-stream'; // Default for unknown types
  }
}

document.addEventListener('DOMContentLoaded', async function() {
  if (CONSOLE_LOG) console.log("CURRENT_PAGE:", CURRENT_PAGE);
  // Initial render
  if(CURRENT_PAGE === "" || CURRENT_PAGE === "index.html"){
    const loadingStatistic = await dataFetchedAndRenderStatistic();
    const loadingActivity = await dataFetchedAndRenderActivity();
  }
});

async function dataFetchedAndRenderStatistic(){
  if (CONSOLE_LOG) console.log("ACCESS_OBJECT:", ACCESS_OBJECT);
  const tables = ACCESS_OBJECT.map(item => item.type);
  if (CONSOLE_LOG) console.log("renderStaticTable tables:", tables);
  
  fetchDataFromServer('/content/all/count', "POST", { email: globalUserEmail, tables: tables })
  .then(data => {
      if (CONSOLE_LOG) console.log(data);
      if(data.status == 200){
        renderStatisticTable(data.data.array);
      } 
      hideLoadingDialog();
  })
  .catch(error => {
    alertModel("ErrorModel-1", 100, [{ href: "#", text: "Close" }], "Something went wrong!", error);
  });
}

function renderStatisticTable(fetchedData){
  let array = [
    { color: 'text-success', iconColor: 'bg-light-success'},
    { color: 'text-secondary', iconColor: 'bg-light-secondary'},
    { color: 'text-warning', iconColor: 'bg-light-warning'},
    { color: 'text-info', iconColor: 'bg-light-info'},
    { color: 'text-warning', iconColor: 'bg-light-warning'},
    { color: 'text-primary', iconColor: 'bg-light-primary'},
    { color: 'text-danger', iconColor: 'bg-light-danger'}
  ];

  array.forEach((item, index) => {
    if (index < ACCESS_OBJECT.length) {
      item.type = ACCESS_OBJECT[index].type;
      item.viewLink = ACCESS_OBJECT[index].pageView;
      item.iconClass = ACCESS_OBJECT[index].iconClass;
    }
  });

  fetchedData.forEach(data => {
    let matchedItem = array.find(item => item.type === data.articleType);
    if (matchedItem) {
      matchedItem.count = data.rowCount;
    }
  });
  
  if (CONSOLE_LOG) console.log("Final Array:", array);

  const staticsTable = document.getElementById('staticsTable');
  if(staticsTable){
    staticsTable.innerHTML = array.map(item => `
      <div class="col-lg-3 col-md-12 col-6 mb-4">
        <div class="card">
          <div class="card-body">
            <div class="card-title d-flex align-items-start justify-content-between">
              <i class="${item.iconClass} card-box-icon ${item.color} ${item.iconColor}"></i>
            </div>
            <span class="fw-semibold d-block mb-1 text-uppercase">${item.type}</span>
            <h3 class="card-title mb-2">${item.count}</h3>
            <a href="${item.viewLink}"><small class="${item.color} fw-semibold">View list</small></a>
          </div>
        </div>
      </div>
    `).join('');
  }
}

async function dataFetchedAndRenderActivity(){
  fetchDataFromServer('/account/activity', "POST", { email: globalUserEmail })
  .then(data => {
      if(data.status == 200){
        renderActivityList(data.data.array);
      } 
      hideLoadingDialog();
  })
  .catch(error => {
    alertModel("ErrorModel-1", 100, [{ href: "#", text: "Close" }], "Something went wrong!", error);
  });
}

function renderActivityList(fetchData){
    if (CONSOLE_LOG) console.log("renderActivityList Array:", fetchData);
    const tbody = document.querySelector("#activityListTable tbody");

    if(tbody){
      tbody.innerHTML = "";
      fetchData.forEach(item => {
        var stateValue = "Added";
        var stateBg = "bg-label-success";
        if(item.state == 2){
          stateValue = "Modified";
          stateBg = "bg-label-warning";
        } else if(item.state == 3){
          stateValue = "Deleted";
          stateBg = "bg-label-danger";
        }

        const currentDateValue = showTheCurrentTimer(item.rowCreatedAt);

        tbody.innerHTML = `
          <tr>
            <td class="d-flex align-items-center">
              <ul class="list-unstyled users-list m-0 avatar-group d-flex align-items-center me-2">
                <li data-bs-toggle="tooltip" data-popup="tooltip-custom" data-bs-placement="top" class="avatar avatar-xs pull-up" title="${item.email}">
                  <img src="${item.profileURL}" alt="Avatar" class="rounded-circle" />
                </li>
              </ul>
              <strong>${item.name}</strong>
            </td>
            <td>${item.type}</td>
            <td><span class="badge ${stateBg} me-1">${stateValue}</span></td>
            <td>${currentDateValue}</td>
          </tr>
          `;
      });
    }
}